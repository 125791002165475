<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.roles')"
                :pgIcon="'bx bx-lock'"
                :refs="refs"
                :pgCurrent="pgCurrent"
                :addNew="false">
            </Breadcrumb>

            <div class="row">

                <Loading
                    v-if="pgLoading">
                </Loading>

                <div v-if="!pgLoading" class="col-8">

                    <FormSingleLang
                        :label="$t('view.role_info')"
                        :labelTitle="$t('view.role_name')"

                        :hasTitle=true
                        :title="row.title"

                        :no_space=true
                        v-on:formChange="handleFormChange">
                    </FormSingleLang>

                    <FormPermissions
                        :permissions_ids="row.permissions_ids"
                        
                        v-on:permissionChange="handlePermissionChange">
                    </FormPermissions>
                </div>

                <div v-if="!pgLoading" class="col-4">

                    <CardStatus
                        :status="row.status"
                        v-on:statusChange="handleStatusChange">
                    </CardStatus>
                
                </div>
            </div>


            <Buttons
                v-if="!pgLoading"
                :btnLoading="btnLoading"
                :btnCurrent="btnCurrent"
                        
                v-on:submitClicked="handelSubmit"
                v-on:cancelClicked="handleCancel">
            </Buttons>

        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'EditOrNew',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Loading: () => import('@/components/Loading.vue'),
        FormSingleLang: () => import('@/components/FormSingleLang.vue'),
        FormPermissions: () => import('@/components/FormPermissions.vue'),
        CardStatus: () => import('@/components/CardStatus.vue'),
        Buttons: () => import('@/components/Buttons.vue')
    },
    data(){
        return {    
            // auth
            auth: {
                role: '',
                access_token: '',
            },

            // row
            row: {
                title: '',
                permissions_ids: [],

                status: 1,
            },
                
            msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
            btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
            methodType: (this.$route.params.id) ? 'PUT' : 'POST',
            pgLoading: (this.$route.params.id) ? true : false,
            pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
            btnLoading: false,
            
            refs: 'roles',
        }
    },
    mounted() {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }

        if(localStorage.getItem('country_id')) {
            this.row.country_id = localStorage.getItem('country_id');
        }


        if(this.$route.params.id) {
            this.fetchRow();
        }
    },
    methods: {
        
        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;

                this.row.title = res.data.row.title;
                this.row.permissions_ids = res.data.row.permissions_ids;

                this.row.status = res.data.row.status;
            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }

            })
            .finally(() => {});
        },
            

        // handle edit Or New submit
        handelSubmit(){
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
            const options = {
                url: (this.$route.params.id) ? window.baseURL+'/'+this.refs+'/'+this.$route.params.id : window.baseURL+'/'+this.refs,
                method: this.methodType,
                data: {
                    name: this.row.title,
                    permissions_ids: this.row.permissions_ids,
                    country_id: this.row.country_id,
                    status: this.row.status
                }
            }
            this.axios(options, config)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'bx bx-wink-smile',
                    title: '',
                    message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
                });
                    
                this.$router.push({ name: this.refs });
            })
            .catch(err => {
                        
                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => {})
        },

          
        // clear Local Storage
        clearLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user_image');
            localStorage.removeItem('user_name');
            localStorage.removeItem('user_id');
            localStorage.removeItem('role');
        },


        // Cancel
        handleCancel(){
            if(confirm('هل انت متأكد؟')) {
                this.$router.push({ name: this.refs });
            }
        },


        // handel Changes from child components
        handleFormChange(event) {
            this.row.title = event.title;
        },

        handlePermissionChange(event) {
            this.row.permissions_ids = event;
        },

        handleStatusChange(event) {
            this.row.status = event.status;
        },

    },
}
</script>
